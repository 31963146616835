import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import NakaLightbox from '../../../components/NakaLightbox'
import Layout from '../../../components/layout'
import FailureMenu from './_menu.js'

const Failure2019TourPage = ({ data }) => (
  <Layout bgClass="clients-failure">
    <FailureMenu pageName="2019_tour" />
    <div className="textPageDetails">
      <p>
        The poster series for Failure’s 2019 tour, in tandem with the release of their album, <i>In the Future Your Body Will Be the Furthest Thing from Your Mind</i>. The band required 33 different posters fitting the sci-fi astronaut theme of their previous tours.
      </p>
    </div>
    <div className="gallery-container">
    <NakaLightbox images={data.allFile.edges} />
    </div>
  </Layout>
)

Failure2019TourPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Failure2019TourPage

export const pageQuery = graphql`
  query Failure2019TourPageIndexQuery {
    allFile(
      filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativePath: {regex: "/failure/2019_tour/"}}
    	sort: {fields: name}
    ) {
      totalCount
      edges {
        node {
          childImageSharp {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
            fluid {
              srcSet
              src
              originalName
              presentationWidth
              presentationHeight
              originalImg
            }
          }
        }
      }
    }
  }
`
